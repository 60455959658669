import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import {
  MDBSideNavCat,
  MDBSideNavNav,
  MDBSideNav,
  MDBSideNavLink,
  MDBContainer,
  MDBRow,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";
import "./sidenav.css";
import DashboardV1 from "./v1";
import DashboardUserCreat from "./DashboardUserCreat";

const Sidenav = (isModalOpen, ...props) => {
  const [isSlim, setIsSlim] = useState(false);
  const [isModalOpenvalue, setIsModalOpen] = useState(isModalOpen);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const history = useHistory();

  const UserDetails = sessionStorage.getItem("LoginUser");
  const UserDetailsData = JSON.parse(UserDetails);
  const toggleSlim = () => {
    setIsSlim(!isSlim);
  };
  const redirectToDashboardUserCreat = () => {
    setIsSidebarOpen(false); // Close the sidenav
    history.push("/DashboardUserCreat");
  };

  const redirectToUserList = () => {
    setIsSidebarOpen(false); // Close the sidenav
    history.push("/UserList");
  };

  const redirectToHousehold = () => {
    setIsSidebarOpen(false); // Close the sidenav
    history.push("/HouseHoldFormData");
  };

  const redirectTofollowups = () => {
    setIsSidebarOpen(false); // Close the sidenav
    history.push("/followups");
  };

  return (
    <div className="sidenav-container">
      {/* {console.log(isModalOpen.isModalOpen, "this.state.sideNavToggled",isSidebarOpen)} */}
      <MDBSideNav
        fixed
        slim={isModalOpen.isModalOpen}
        breakWidth={0}
        className={`sidenav ${isSidebarOpen ? "open" : ""}`}
        style={{
          width: isSidebarOpen ? "300px" : "50px",
          color: "#111C43",
        }}

      >
        {/* <button onClick={toggleSlim}>onClick</button> */}
        <MDBSideNavNav>
          {/* Home tab */}
          <span style={{
            color: "white",
            fontSize: "23px",
            marginLeft: "27px",
            marginTop: "18px",
          }}>
            INCENTIVE
          </span>
          {/* <a href="/dashboard">
            <MDBSideNavCat
              name="Home"
              id="Home"
              icon="far fa-home"
              style={{
                color: "white",
                fontSize: "16px",
                marginLeft: "-24px",
                marginTop: "18px",
              }}
              className="no-arrow"

            />
          </a> */}
          <a
            href="/dashboard"
            style={{
              color: "white",
              fontSize: "16px",
              marginTop: "24px",
              fontWeight: "400"
            }}
            className="custom-style"
          >
            <i className="fas fa-home" style={{fontSize:"15px", marginRight: "5px", color: "white" }}></i>
            Home
          </a>

          {/* Manage User Tab */}
          <MDBSideNavCat
            name="Manage User"
            id="Create-User"
            icon="far fa-user"
            style={{
              arrow: "none",
              color: "white",
              fontSize: "16px",
              marginTop: "24px",
            }}
          >
            {/* Inside Tabs */}
            {UserDetailsData.ROLE === "0" && (
              <MDBSideNavLink
                style={{ color: "white" }}
                to="/DashboardUserCreat"
                onClick={redirectToDashboardUserCreat}
              >
                ADD User
              </MDBSideNavLink>
            )}

            <MDBSideNavLink
              style={{ color: "white" }}
              to="/UserList"
              onClick={redirectToUserList}
            >
              Users List
            </MDBSideNavLink>
          </MDBSideNavCat>

          {/* My Profile Tab */}

          {/* <MDBSideNavCat
            name="My Profile"
            id="user profile"
            icon="fas fa-user-astronaut"
            style={{ color: "white", fontSize: "16px", marginTop: "30px" }}
          ></MDBSideNavCat> */}

          {/* View/Download Data Tab */}
          <MDBSideNavCat
            name="View/Download Data"
            id="reports"
            icon="fas fa-book"
            style={{
              arrow: "none",
              color: "white",
              fontSize: "16px",
              marginTop: "30px",
            }}
          >
            {/* Inside Tab */}
            <MDBSideNavLink
              style={{ color: "white" }}
              to="/HouseHoldFormData"
              onClick={redirectToHousehold}
            >
              HouseHold Form Data
            </MDBSideNavLink>
            <MDBSideNavLink
              style={{ color: "white" }}
              to="/followups"
              onClick={redirectTofollowups}
            >
              Follow Ups Form Data
            </MDBSideNavLink>
          </MDBSideNavCat>
        </MDBSideNavNav>
      </MDBSideNav>
    </div>
  );
};

export default Sidenav;
