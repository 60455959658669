//prod
const baseUrl = "https://incentive.clinally.com/webuser";// PR URL
const apiURL = "https://incentive.clinally.com/webuser";

// qa
// const baseUrl = "http://incentive.pragmatiqinc.com/pulsedashboard";
// const apiURL = "http://incentive.pragmatiqinc.com"; // QA URL

// localhost
// const baseUrl = "http://localhost:3770/webuser";
// const apiURL = "http://localhost:3770/webuser";

// qa
// const baseUrl = "https://starncdqa.pragmatiqinc.com/webuser";
// const apiURL = "https://starncdqa.pragmatiqinc.com/webuser";

const routes = {
  // users: '/users',
};

export default { baseUrl, routes, apiURL };
