import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBIcon,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";
import { HiMenuAlt1, HiOutlineX } from "react-icons/hi";

import "./TopNavigation.css";

const TopNavigation = (props) => {
  const logout = () => {
    sessionStorage.clear();
    window.location.href = "/";
  };
  const navStyle = {
    // paddingLeft: props.toggle ? "16px" : "16px",
    marginLeft: "36px",
    transition: "padding-left .3s",
  };
  const UserDetails = sessionStorage.getItem("LoginUser");
  const UserDetailsData = JSON.parse(UserDetails);
  return (
    <Router>
      <MDBNavbar
        className="flexible-MDBNavbar"
        light
        expand="md"
        scrolling
        fixed="top"
        style={{ zIndex: 3,padding:'0%' }}
      >

        {/* navbar open and close  */}
        {/* <MDBNavbarBrand>
          {props.toggle ? (
            <HiOutlineX
              style={{ marginLeft: props.toggle ? "0" : 0 }}
              onClick={props.onSideNavToggleClick}
            />
          ) : (
            <HiMenuAlt1
              style={{ marginLeft: props.toggle ? "0" : 0 }}
              onClick={props.onSideNavToggleClick}
            />
          )}
        </MDBNavbarBrand> */}

        <MDBNavbarBrand style={navStyle}>
          {/* <strong className="incentiveText">INCENTIVE ADMIN DASHBOARD</strong> */}
          <strong className="incentiveText"> DASHBOARD</strong>
        </MDBNavbarBrand>

        <MDBNavbarNav expand="sm" right style={{ flexDirection: "row" ,marginRight:'4%'}}>
          <MDBDropdown>
            <MDBDropdownToggle
              nav
              caret
              style={{ display: "flex", alignItems: "center",    gap: "10%"
            }}
            >
              <span>
                <MDBIcon
                  icon="user"
                  style={{
                    padding: "7px",
                    border: "2px solid",
                    borderRadius: "50%",
                    margin: "0",
                    marginRight: "3%",
                    fontSize: "20px",
                  }}
                />{" "}
              </span>
              <div className="profilemain_container">
                {" "}
                <div className="profilemain_container1">
                  <span
                    className="d-none d-md-inline"
                    style={{ fontSize: "17px" }}
                  >
                    {/* Profile */}
                    {UserDetailsData.FIRST_NAME}
                  </span>
                   <MDBDropdownMenu right style={{ minWidth: "200px" }}>
                   {/* <MDBDropdownItem>
               
                      {UserDetailsData.ROLE === "0" ? "Adimin" : null}
                      {UserDetailsData.ROLE === "10"
                        ? "Project supervisor"
                        : null}
                      {UserDetailsData.ROLE === "11"
                        ? "Data supervisor "
                        : null}
                      {UserDetailsData.ROLE === "20"
                        ? "Project supervisor"
                        : null}
                      {UserDetailsData.ROLE === "21" ? "Data supervisor" : null}
                  
                    </MDBDropdownItem> */}
                    <MDBDropdownItem onClick={(e) => logout(e)}>
                      Logout{" "}
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </div>
                <span  className="d-none d-md-inline"
                    style={{ fontSize: "15px" }}>
                
                  {UserDetailsData.ROLE === "0" ? "Admin" : null}
                  {UserDetailsData.ROLE === "10" ? "Project supervisor" : null}
                  {UserDetailsData.ROLE === "11" ? "Data supervisor " : null}
                  {UserDetailsData.ROLE === "20" ? "Project supervisor" : null}
                  {UserDetailsData.ROLE === "21" ? "Data supervisor" : null}
              
                </span>
              </div>
            </MDBDropdownToggle>
          </MDBDropdown>
        </MDBNavbarNav>
      </MDBNavbar>
    </Router>
  );
};

export default TopNavigation;
