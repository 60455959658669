// // import React, { useState } from "react";
// // import { MDBCard, MDBCardBody, MDBCardHeader } from "mdbreact";
// // import { MDBSideNav, MDBSideNavNav, MDBSideNavLink, MDBIcon } from "mdbreact";

// // import { ToastContainer, toast } from "react-toastify";
// // import "react-toastify/dist/ReactToastify.css";
// // import Axios from "axios";
// // import { useHistory } from "react-router-dom";
// // import "./UserCreate.css";
// // import DashboardV2 from "./v2";
// // import config from "../../config";

// // const DashboardUserCreat = ({ onUserAdded }) => {
// //   const [selectGender, setSelectGender] = useState([]);
// //   const history = useHistory();
// //   const genderOptions = [
// //     { text: "Male", value: "male" },
// //     { text: "Female", value: "female" },
// //   ];

// //   const [error, setError] = useState("");
// //   const [mobileError, setMobileError] = useState("");
// //   const [data, setData] = useState([]);
// //   const [showSuccessPopup, setShowSuccessPopup] = useState(false);
// //   const [formValues, setFormValues] = useState({
// //     firstname: "",
// //     lastname: "",
// //     email: "",
// //     phonenumber: "",
// //     gender: "",
// //     city: "",
// //     state: "",
// //     region: "",
// //     role: "",
// //     password: "1234",
// //   });

// //   const onChangeA = (e, ctrlName, ctrlValue) => {
// //     setFormValues((prevFormValues) => ({
// //       ...prevFormValues,
// //       [ctrlName]: ctrlValue,
// //     }));
// //   };

// //   const isValidEmail = (email) => {
// //     return /\S+@\S+\.\S+/.test(email);
// //   };

// //   const handleMobileChange = (e) => {
// //     const regex = /^[0-9]{10}$/;
// //     if (!regex.test(e.target.value)) {
// //       setMobileError("* Enter Valid Mobile No");
// //     } else {
// //       setMobileError("");
// //     }
// //     onChangeA(e, "phonenumber", e.target.value);
// //   };
// //   const handleEmailChange = (e) => {
// //     if (!isValidEmail(e.target.value)) {
// //       setError("Invalid Email");
// //     } else {
// //       setError("");
// //     }
// //     onChangeA(e, "email", e.target.value);
// //   };

// //   const addUser = (e) => {
// //     e.preventDefault();

// //     if (
// //       formValues.firstname !== "" &&
// //       formValues.lastname !== "" &&
// //       formValues.email !== "" &&
// //       formValues.phonenumber !== "" &&
// //       formValues.gender !== "" &&
// //       formValues.city !== "" &&
// //       formValues.state !== "" &&
// //       formValues.region !== "" &&
// //       formValues.role !== ""
// //     ) {
// //       const payload = {
// //         FIRST_NAME: formValues.firstname,
// //         LAST_NAME: formValues.lastname,
// //         MOBILE: formValues.phonenumber,
// //         EMAIL: formValues.email,
// //         GENDER: formValues.gender,
// //         CITY: formValues.city,
// //         STATE: formValues.state,
// //         REGION: formValues.region,
// //         PASSWORD: formValues.password,
// //         ROLE: formValues.role,
// //         Status: 0,
// //       };

// //       console.log("Form Values:", formValues);
// //       console.log("Payload:", payload);

// //       Axios.post(`${config.baseUrl}/addUser`, payload, {
// //         "content-type": "application/json",
// //         Accept: "application/json",
// //       })
// //         .then((response) => {
// //           console.log("Response:", response);

// //           if (response?.status === 200) {
// //             setData([...data, payload]);
// //             setShowSuccessPopup(true);
// //             toast.success("User Added Successfully");

// //             setFormValues({
// //               firstname: "",
// //               lastname: "",
// //               email: "",
// //               phonenumber: "",
// //               gender: "",
// //               city: "",
// //               state: "",
// //               region: "",
// //               password: "",
// //               role: "",
// //             });
// //           } else {
// //             console.error("Error adding user:", response?.status);
// //             toast.error("Error adding user. Please try again.");
// //           }
// //         })
// //         .catch((error) => {
// //           console.error("Error adding user:", error);
// //           toast.error("Error adding user. Please try again.");
// //         });
// //     } else {
// //       toast.error("Please enter all the fields.", {
// //         position: "top-right",
// //       });
// //     }
// //   };

// //   return (
// //     <div>
// //       <style>
// //         {`
// //         body, html {
// //           margin: 0;
// //           padding: 0;
// //           min-height: 100%;
// //           background-color: #FFFF;
// //         }
// //       `}
// //       </style>{" "}
// //       <h3 className="pageheading">User Creation </h3>
// //       <MDBCard
// //         style={{
// //           background: "#F3F3F3",

// //           borderRadius: "12px",

// //           boxShadow: "none",
// //         }}
// //       >
// //         <MDBCardHeader className="userCreateCardHeader" id="UserCreation">
// //           <p> User Details</p>
// //         </MDBCardHeader>
// //         <MDBCardBody>
// //           <form className="user-form">
// //             <div className="user-form-container">
// //               <div className="user-form-row">
// //                 <div className="user-form-field">
// //                   <label className="user-form-label" htmlFor="firstname">
// //                     First Name *
// //                   </label>
// //                   <input
// //                     className="user-form-input"
// //                     type="text"
// //                     id="firstname"
// //                     name="firstname"
// //                     placeholder="First Name"
// //                     onChange={(e) => onChangeA(e, "firstname", e.target.value)}
// //                   />
// //                 </div>

// //                 <div className="user-form-field">
// //                   <label className="user-form-label" htmlFor="lastname">
// //                     Last Name *
// //                   </label>
// //                   <input
// //                     className="user-form-input"
// //                     type="text"
// //                     id="lastname"
// //                     name="lastname"
// //                     placeholder="Last Name"
// //                     onChange={(e) => onChangeA(e, "lastname", e.target.value)}
// //                   />
// //                 </div>

// //                 <div className="user-form-field">
// //                   <label className="user-form-label" htmlFor="email">
// //                     Email *
// //                   </label>
// //                   <input
// //                     className="user-form-input"
// //                     type="text"
// //                     id="email"
// //                     name="email"
// //                     placeholder="Email"
// //                     onChange={handleEmailChange}
// //                   />
// //                 </div>
// //               </div>

// //               <div className="user-form-row">
// //                 <div className="user-form-field">
// //                   <label className="user-form-label" htmlFor="mobile">
// //                     Mobile No *
// //                   </label>
// //                   <input
// //                     className="user-form-input"
// //                     type="text"
// //                     id="mobile"
// //                     name="mobile"
// //                     placeholder="Phone Number"
// //                     maxLength={10}
// //                     onChange={handleMobileChange}
// //                   />
// //                 </div>

// //                 <div className="user-form-field">
// //                   <label className="user-form-label" htmlFor="gender">
// //                     Gender *
// //                   </label>
// //                   <select
// //                     className="user-form-select"
// //                     id="gender"
// //                     value={formValues.gender}
// //                     onChange={(e) => onChangeA(e, "gender", e.target.value)}
// //                   >
// //                     <option value="" disabled>
// //                       Choose Gender
// //                     </option>
// //                     <option value="male">Male</option>
// //                     <option value="female">Female</option>
// //                     <option value="others">Others</option>
// //                   </select>
// //                 </div>

// //                 <div className="user-form-field">
// //                   <label className="user-form-label" htmlFor="city">
// //                     City *
// //                   </label>
// //                   <select
// //                     className="user-form-select"
// //                     id="city"
// //                     name="city"
// //                     onChange={(e) => onChangeA(e, "city", e.target.value)}
// //                   >
// //                     <option value="">Select City</option>
// //                     <option value="Vishakapatnam">Vishakapatnam</option>
// //                     <option value="Sonipat">Sonipat</option>
// //                   </select>
// //                 </div>
// //               </div>

// //               <div className="user-form-row">
// //                 <div className="user-form-field">
// //                   <label className="user-form-label" htmlFor="state">
// //                     State *
// //                   </label>
// //                   <select
// //                     className="user-form-select"
// //                     id="state"
// //                     name="state"
// //                     onChange={(e) => onChangeA(e, "state", e.target.value)}
// //                   >
// //                     <option value="">Select State</option>
// //                     <option value="Andhra Pradesh">Andhra Pradesh</option>
// //                     <option value="Haryana">Haryana</option>
// //                   </select>
// //                 </div>

// //                 <div className="user-form-field">
// //                   <label className="user-form-label" htmlFor="region">
// //                     Region *
// //                   </label>
// //                   <select
// //                     className="user-form-select"
// //                     id="region"
// //                     name="region"
// //                     onChange={(e) => onChangeA(e, "region", e.target.value)}
// //                   >
// //                     <option value="">Select Region</option>
// //                     <option value="urban">Urban</option>
// //                     <option value="rural">Rural</option>
// //                   </select>
// //                 </div>

// //                 <div className="user-form-field">
// //                   <label className="user-form-label" htmlFor="role">
// //                     Role *
// //                   </label>
// //                   <select
// //                     className="user-form-select"
// //                     id="role"
// //                     name="role"
// //                     onChange={(e) => onChangeA(e, "role", e.target.value)}
// //                   >
// //                     <option value="">Select Role</option>
// //                     <option value="4">Mobile App user</option>
// //                     <option value="0">Web Admin</option>
// //                     <option value="10">Vishakapatnam Project SP</option>
// //                     <option value="20">Sonipat Project SP</option>
// //                     <option value="11">Vishakapatnam Data SP</option>
// //                     <option value="21">Sonipat Data SP</option>
// //                   </select>
// //                 </div>
// //               </div>

// //               <button className="user-form-button" onClick={addUser}>
// //                 Add User
// //               </button>
// //             </div>
// //           </form>

// //           <ToastContainer />
// //         </MDBCardBody>
// //       </MDBCard>
// //     </div>
// //   );
// // };

// // export default DashboardUserCreat;

// import React, { useState } from "react";
// import { MDBCard, MDBCardBody, MDBCardHeader } from "mdbreact";
// import { ToastContainer, toast } from "react-toastify";
// import Axios from "axios";
// import { useHistory } from "react-router-dom";
// import "react-toastify/dist/ReactToastify.css";
// import "./UserCreate.css";
// import config from "../../config";

// const DashboardUserCreat = ({ onUserAdded }) => {
//   const history = useHistory();
//   const [error, setError] = useState("");
//   const [mobileError, setMobileError] = useState("");
//   const [data, setData] = useState([]);
//   const [showSuccessPopup, setShowSuccessPopup] = useState(false);
//   const [formValues, setFormValues] = useState({
//     firstname: "",
//     lastname: "",
//     email: "",
//     phonenumber: "",
//     gender: "",
//     city: "",
//     state: "",
//     region: "",
//     role: "",
//     password: "",
//     loginId: "", // New field for Login ID
//   });

//   const onChangeA = (e, ctrlName, ctrlValue) => {
//     setFormValues((prevFormValues) => ({
//       ...prevFormValues,
//       [ctrlName]: ctrlValue,
//     }));
//   };

//   const isValidEmail = (email) => {
//     return /\S+@\S+\.\S+/.test(email);
//   };

//   const handleMobileChange = (e) => {
//     const regex = /^[0-9]{10}$/;
//     if (!regex.test(e.target.value)) {
//       setMobileError("* Enter Valid Mobile No");
//     } else {
//       setMobileError("");
//     }
//     onChangeA(e, "phonenumber", e.target.value);
//   };

//   const handleEmailChange = (e) => {
//     if (!isValidEmail(e.target.value)) {
//       setError("Invalid Email");
//     } else {
//       setError("");
//     }
//     onChangeA(e, "email", e.target.value);
//   };

//   const addUser = (e) => {
//     e.preventDefault();

//     // Validate Login ID only if the role is 'Mobile App user'
//     if (formValues.role === "4" && formValues.loginId.trim() === "") {
//       toast.error("Login ID is required for Mobile App user.");
//       return;
//     }

//     if (
//       formValues.firstname !== "" &&
//       formValues.lastname !== "" &&
//       formValues.email !== "" &&
//       formValues.phonenumber !== "" &&
//       formValues.gender !== "" &&
//       formValues.city !== "" &&
//       formValues.state !== "" &&
//       formValues.region !== "" &&
//       formValues.role !== "" &&
//       formValues.password !== "" &&
//       formValues.role === "4"
//         ? formValues.loginId !== ""
//         : true
//     ) {
//       const payload = {
//         FIRST_NAME: formValues.firstname,
//         LAST_NAME: formValues.lastname,
//         MOBILE: formValues.phonenumber,
//         EMAIL: formValues.email,
//         GENDER: formValues.gender,
//         CITY: formValues.city,
//         STATE: formValues.state,
//         REGION: formValues.region,
//         PASSWORD: formValues.password,
//         ROLE: formValues.role,
//         LOGIN_ID: formValues.role === "4" ? formValues.loginId : null, // Include Login ID only if role is Mobile App user
//         Status: 0,
//       };

//       console.log("Form Values:", formValues,formValues.password,    formValues.password !== "" );
//       console.log("Payload:", payload);

//       Axios.post(`${config.baseUrl}/addUser`, payload, {
//         "content-type": "application/json",
//         Accept: "application/json",
//       })
//         .then((response) => {
//           if (response?.status === 200) {
//             setData([...data, payload]);
//             setShowSuccessPopup(true);
//             toast.success("User Added Successfully");

//             // Reset form values
//             setFormValues({
//               firstname: "",
//               lastname: "",
//               email: "",
//               phonenumber: "",
//               gender: "",
//               city: "",
//               state: "",
//               region: "",
//               password: "",
//               role: "",
//               loginId: "", // Reset Login ID
//             });
//           } else if (response?.status === 301) {
//             toast.error(response?.data?.error.message);
//           }
//         })
//         .catch((error) => {
//           toast.error(
//             error.response.data.error.message
//               ? error.response.data.error.message
//               : "Error adding user. Please try again."
//           );
//         });
//     } else {
//       toast.error("Please enter all the fields.", {
//         position: "top-right",
//       });
//     }
//   };

//   return (
//     <div>
//       <style>
//         {`
//         body, html {
//           margin: 0;
//           padding: 0;
//           min-height: 100%;
//           background-color: #FFFF;
//         }
//       `}
//       </style>
//       <h3 className="pageheading">User Creation </h3>
//       <MDBCard
//         style={{
//           background: "#F3F3F3",
//           borderRadius: "12px",
//           boxShadow: "none",
//         }}
//       >
//         <MDBCardHeader className="userCreateCardHeader" id="UserCreation">
//           <p>User Details</p>
//         </MDBCardHeader>
//         <MDBCardBody>
//           <form className="user-form">
//             <div className="user-form-container">
//               <div className="user-form-row">
//                 <div className="user-form-field">
//                   <label className="user-form-label" htmlFor="firstname">
//                     First Name *
//                   </label>
//                   <input
//                     className="user-form-input"
//                     type="text"
//                     id="firstname"
//                     name="firstname"
//                     placeholder="First Name"
//                     onChange={(e) => onChangeA(e, "firstname", e.target.value)}
//                   />
//                 </div>

//                 <div className="user-form-field">
//                   <label className="user-form-label" htmlFor="lastname">
//                     Last Name *
//                   </label>
//                   <input
//                     className="user-form-input"
//                     type="text"
//                     id="lastname"
//                     name="lastname"
//                     placeholder="Last Name"
//                     onChange={(e) => onChangeA(e, "lastname", e.target.value)}
//                   />
//                 </div>

//                 <div className="user-form-field">
//                   <label className="user-form-label" htmlFor="email">
//                     Email *
//                   </label>
//                   <input
//                     className="user-form-input"
//                     type="text"
//                     id="email"
//                     name="email"
//                     placeholder="Email"
//                     onChange={handleEmailChange}
//                   />
//                 </div>
//               </div>

//               <div className="user-form-row">
//                 <div className="user-form-field">
//                   <label className="user-form-label" htmlFor="mobile">
//                     Mobile No *
//                   </label>
//                   <input
//                     className="user-form-input"
//                     type="text"
//                     id="mobile"
//                     name="mobile"
//                     placeholder="Phone Number"
//                     maxLength={10}
//                     onChange={handleMobileChange}
//                   />
//                 </div>

//                 <div className="user-form-field">
//                   <label className="user-form-label" htmlFor="gender">
//                     Gender *
//                   </label>
//                   <select
//                     className="user-form-select"
//                     id="gender"
//                     value={formValues.gender}
//                     onChange={(e) => onChangeA(e, "gender", e.target.value)}
//                   >
//                     <option value="" disabled>
//                       Choose Gender
//                     </option>
//                     <option value="male">Male</option>
//                     <option value="female">Female</option>
//                     <option value="others">Others</option>
//                   </select>
//                 </div>

//                 <div className="user-form-field">
//                   <label className="user-form-label" htmlFor="city">
//                     City *
//                   </label>
//                   <select
//                     className="user-form-select"
//                     id="city"
//                     name="city"
//                     onChange={(e) => onChangeA(e, "city", e.target.value)}
//                   >
//                     <option value="">Select City</option>
//                     <option value="Vishakapatnam">Vishakapatnam</option>
//                     <option value="Sonipat">Sonipat</option>
//                   </select>
//                 </div>
//               </div>

//               <div className="user-form-row">
//                 <div className="user-form-field">
//                   <label className="user-form-label" htmlFor="state">
//                     State *
//                   </label>
//                   <select
//                     className="user-form-select"
//                     id="state"
//                     name="state"
//                     onChange={(e) => onChangeA(e, "state", e.target.value)}
//                   >
//                     <option value="">Select State</option>
//                     <option value="Andhra Pradesh">Andhra Pradesh</option>
//                     <option value="Haryana">Haryana</option>
//                   </select>
//                 </div>

//                 <div className="user-form-field">
//                   <label className="user-form-label" htmlFor="region">
//                     Region *
//                   </label>
//                   <select
//                     className="user-form-select"
//                     id="region"
//                     name="region"
//                     onChange={(e) => onChangeA(e, "region", e.target.value)}
//                   >
//                     <option value="">Select Region</option>
//                     <option value="Urban">Urban</option>
//                     <option value="Rural">Rural</option>
//                   </select>
//                 </div>

//                 <div className="user-form-field">
//                   <label className="user-form-label" htmlFor="role">
//                     Role *
//                   </label>
//                   <select
//                     className="user-form-select"
//                     id="role"
//                     value={formValues.role}
//                     onChange={(e) => onChangeA(e, "role", e.target.value)}
//                   >
//                     <option value="" disabled>
//                       Select Role
//                     </option>
//                     <option value="0">Web Admin</option>
//                     <option value="10">Vishakapatnam Project SP</option>
//                     <option value="20">Sonipat Project SP</option>
//                     <option value="11">Vishakapatnam Data SP`</option>
//                     <option value="21">Sonipat Data SP</option>
//                     <option value="4">Mobile App user</option>{" "}
//                     {/* Mobile App user option */}
//                   </select>
//                 </div>
//               </div>

//               {/* Conditionally render Login ID field */}
//               <div className="user-form-row">
//                 {formValues.role === "4" && (
//                   <div className="user-form-field">
//                     <label className="user-form-label" htmlFor="loginId">
//                       Login ID *
//                     </label>
//                     <input
//                       className="user-form-input"
//                       type="text"
//                       id="loginId"
//                       name="loginId"
//                       placeholder="Login ID"
//                       value={formValues.loginId}
//                       onChange={(e) => onChangeA(e, "loginId", e.target.value)}
//                     />
//                   </div>
//                 )}
//                 <div className="user-form-field">
//                   <label className="user-form-label" htmlFor="mobile">
//                     Password *
//                   </label>
//                   <input
//                     className="user-form-input"
//                     type="text"
//                     id="password"
//                     name="password"
//                     placeholder="password"
//                     maxLength={10}
//                     onChange={handleMobileChange}
//                   />
//                 </div>{" "}
//               </div>
//             </div>
//             <button className="user-form-button" onClick={addUser}>
//               Add User
//             </button>
//           </form>
//           <ToastContainer />
//         </MDBCardBody>
//       </MDBCard>
//     </div>
//   );
// };

// export default DashboardUserCreat;

import React, { useState } from "react";
import { MDBCard, MDBCardBody, MDBCardHeader } from "mdbreact";
import { ToastContainer, toast } from "react-toastify";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./UserCreate.css";
import config from "../../config";

const DashboardUserCreat = ({ onUserAdded }) => {
  const history = useHistory();
  const [error, setError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [data, setData] = useState([]);
  const [formValues, setFormValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phonenumber: "",
    gender: "",
    city: "",
    state: "",
    region: "",
    role: "",
    password: "",
    loginId: "", // New field for Login ID
  });

  const onChangeA = (e, ctrlName, ctrlValue) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [ctrlName]: ctrlValue,
    }));
  };

  const onChangealpha = (e, ctrlName, ctrlValue) => {
    const alphabetRegex = /^[a-zA-Z]*$/; // Allows only alphabets
    if (alphabetRegex.test(ctrlValue)) {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [ctrlName]: ctrlValue,
      }));
    }
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleMobileChange = (e) => {
    const regex = /^[0-9]{10}$/;
    const value = e.target.value;
    if (!regex.test(value)) {
      setMobileError("* Enter Valid Mobile No");
    } else {
      setMobileError("");
    }
    onChangeA(e, "phonenumber", value);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    if (!isValidEmail(value)) {
      setError("Invalid Email");
    } else {
      setError("");
    }
    onChangeA(e, "email", value);
  };

  const addUser = (e) => {
    e.preventDefault();
    console.log(formValues);
    // Check if any required fields are missing
    const missingFields = Object.keys(formValues).filter((key) => {
      return key !== "loginId" && !formValues[key];
    });

    if (missingFields.length > 0) {
      toast.error("Please fill in all required fields.");
      return;
    }

    // Validate Login ID only if the role is 'Mobile App user'
    if (formValues.role === "4" && formValues.loginId.trim() === "") {
      toast.error("Login ID is required for Mobile App user.");
      return;
    }

    // Perform email and phone number validations
    if (error || mobileError) {
      toast.error("Please correct the errors in the form.");
      return;
    }

    const payload = {
      FIRST_NAME: formValues.firstname,
      LAST_NAME: formValues.lastname,
      MOBILE: formValues.phonenumber,
      EMAIL: formValues.email,
      GENDER: formValues.gender,
      CITY: formValues.city,
      STATE: formValues.state,
      REGION: formValues.region,
      PASSWORD: formValues.password,
      ROLE: formValues.role,
      LOGIN_ID: formValues.role === "4" ? formValues.loginId : null,
      Status: 0,
    };

    Axios.post(`${config.baseUrl}/addUser`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setData([...data, payload]);

          // Reset form values
          setFormValues({
            firstname: "",
            lastname: "",
            email: "",
            phonenumber: "",
            gender: "",
            city: "",
            state: "",
            region: "",
            password: "",
            role: "",
            loginId: "",
          });
          toast.success("User Added Successfully");
        } else if (response.status === 301) {
          toast.error(response.data.error.message);
        }
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.error?.message ||
          "Error adding user. Please try again."
        );
      });
  };

  return (
    <div>
      <h3 className="pageheading">User Creation</h3>
      <MDBCard
        style={{
          background: "#F3F3F3",
          borderRadius: "12px",
          boxShadow: "none",
        }}
      >
        <MDBCardHeader className="userCreateCardHeader" id="UserCreation">
          <p>User Details</p>
        </MDBCardHeader>
        <MDBCardBody>
          <form className="user-form">
            <div className="user-form-container">
              <div className="user-form-row">
                <div className="user-form-field">
                  <label className="user-form-label" htmlFor="firstname">
                    First Name *
                  </label>
                  <input
                    className="user-form-input"
                    type="text"
                    id="firstname"
                    name="firstname"
                    value={formValues.firstname}
                    placeholder="First Name"
                    onChange={(e) => onChangealpha(e, "firstname", e.target.value)}
                  />
                </div>
                <div className="user-form-field">
                  <label className="user-form-label" htmlFor="lastname">
                    Last Name *
                  </label>
                  <input
                    className="user-form-input"
                    type="text"
                    id="lastname"
                    name="lastname"
                    value={formValues.lastname}
                    placeholder="Last Name"
                    onChange={(e) => onChangealpha(e, "lastname", e.target.value)}
                  />
                </div>
                <div className="user-form-field">
                  <label className="user-form-label" htmlFor="email">
                    Email *
                  </label>
                  <input
                    className="user-form-input"
                    type="text"
                    id="email"
                    name="email"
                    value={formValues.email}
                    placeholder="Email"
                    onChange={handleEmailChange}
                  />
                </div>
              </div>
              <div className="user-form-row">
                <div className="user-form-field">
                  <label className="user-form-label" htmlFor="mobile">
                    Mobile No *
                  </label>
                  <input
                    className="user-form-input"
                    type="text"
                    id="mobile"
                    name="mobile"
                    value={formValues.phonenumber}
                    placeholder="Phone Number"
                    maxLength={10}
                    onChange={handleMobileChange}
                  />
                </div>
                <div className="user-form-field">
                  <label className="user-form-label" htmlFor="gender">
                    Gender *
                  </label>
                  <select
                    className="user-form-select"
                    id="gender"
                    value={formValues.gender}
                    onChange={(e) => onChangeA(e, "gender", e.target.value)}
                  >
                    <option value="" disabled>
                      Choose Gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="others">Others</option>
                  </select>
                </div>
                <div className="user-form-field">
                  <label className="user-form-label" htmlFor="city">
                    City *
                  </label>
                  <select
                    className="user-form-select"
                    id="city"
                    name="city"
                    value={formValues.city}
                    onChange={(e) => onChangeA(e, "city", e.target.value)}
                  >
                    <option value="">Select City</option>
                    <option value="Vishakapatnam">Vishakapatnam</option>
                    <option value="Sonipat">Sonipat</option>
                  </select>
                </div>
              </div>
              <div className="user-form-row">
                <div className="user-form-field">
                  <label className="user-form-label" htmlFor="state">
                    State *
                  </label>
                  <select
                    className="user-form-select"
                    id="state"
                    name="state"
                    value={formValues.state}
                    onChange={(e) => onChangeA(e, "state", e.target.value)}
                  >
                    <option value="">Select State</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Haryana">Haryana</option>
                  </select>
                </div>
                <div className="user-form-field">
                  <label className="user-form-label" htmlFor="region">
                    Region *
                  </label>
                  <select
                    className="user-form-select"
                    id="region"
                    name="region"
                    value={formValues.region}
                    onChange={(e) => onChangeA(e, "region", e.target.value)}
                  >
                    <option value="">Select Region</option>
                    <option value="Urban">Urban</option>
                    <option value="Rural">Rural</option>
                  </select>
                </div>
                <div className="user-form-field">
                  <label className="user-form-label" htmlFor="role">
                    Role *
                  </label>
                  <select
                    className="user-form-select"
                    id="role"
                    value={formValues.role}
                    // value={formValues.firstname}
                    onChange={(e) => onChangeA(e, "role", e.target.value)}
                  >
                    <option value="" disabled>
                      Select Role
                    </option>
                    <option value="0">Web Admin</option>
                    <option value="10">Vishakapatnam Project SP</option>
                    <option value="20">Sonipat Project SP</option>
                    <option value="11">Vishakapatnam Data SP`</option>
                    <option value="21">Sonipat Data SP</option>
                    <option value="4">Mobile App user</option>{" "}
                    {/* Mobile App user option */}
                  </select>
                </div>
              </div>
              {/* Conditionally render Login ID field */}
              <div className="user-form-row">
                {formValues.role === "4" && (
                  <div className="user-form-field">
                    <label className="user-form-label" htmlFor="loginId">
                      Login ID *
                    </label>
                    <input
                      className="user-form-input"
                      type="text"
                      id="loginId"
                      name="loginId"
                      placeholder="Login ID"
                      value={formValues.loginId}
                      onChange={(e) => onChangeA(e, "loginId", e.target.value)}
                    />
                  </div>
                )}
                <div className="user-form-field">
                  <label className="user-form-label" htmlFor="mobile">
                    Password *
                  </label>
                  <input
                    className="user-form-input"
                    type="text"
                    id="password"
                    name="password"
                    placeholder="password"
                    maxLength={10}
                    value={formValues.password}
                    onChange={(e) => onChangeA(e, "password", e.target.value)}
                  />
                </div>{" "}
              </div>
            </div>
            <button className="user-form-button" onClick={addUser}>
              Add User
            </button>
          </form>

          <ToastContainer />
        </MDBCardBody>
      </MDBCard>
    </div>
  );
};

export default DashboardUserCreat;
