import React, { useState, useEffect, useRef } from "react";
import {
  MDBDataTable,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBIcon,
  MDBSpinner,
} from "mdbreact";

import Axios from "axios";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import maleIcon from "../../images/male-icon.png";
import femaleIcon from "../../images/female-icon.png";
import config from "../../config";
import "../../index.css";
import { color } from "echarts";


const UserList = () => {
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userToDelete, setUserToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const history = useHistory();
  const tableRef = useRef(null);
  const UserDetails = sessionStorage.getItem("LoginUser");
  const UserDetailsData = JSON.parse(UserDetails);


  // const getfunction = () => {
  //   setLoading(true);
  //   if (UserDetailsData.ROLE === "0") {
  //     Axios.get(`${config.baseUrl}/users/Both/Both`)

  //       .then((response) => {
  //         console.log(response.data, "response.data");
  //         setUsersData(response.data);
  //         setLoading(false);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching user data:", error);
  //         setLoading(false);
  //       });
  //   } else {
  //     Axios.get(`${config.baseUrl}/users/${UserDetailsData.CITY}/Both`)

  //       .then((response) => {
  //         console.log(response.data, "response.data");
  //         setUsersData(response.data);
  //         setLoading(false);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching user data:", error);
  //       });
  //   }
  // };
  // useEffect(() => {
  //   getfunction();
  // }, []);

  const getfunction = () => {
    setLoading(true); // Set loading state to true at the start

    if (UserDetailsData.ROLE === "0") {
      Axios.get(`${config.baseUrl}/users/Both/Both`)
        .then((response) => {
          console.log(response.data, "response.data");
          setUsersData(response.data);
          setLoading(false); // Set loading to false after successful response
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          setLoading(false); // Ensure loading is turned off in case of an error
        });
    } else {
      Axios.get(`${config.baseUrl}/users/${UserDetailsData.CITY}/Both`)
        .then((response) => {
          console.log(response.data, "response.data");
          setUsersData(response.data);
          setLoading(false); // Set loading to false after successful response
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          setLoading(false); // Ensure loading is turned off in case of an error
        });
    }
  };

  useEffect(() => {
    getfunction();
  }, []);


  const handleUpdate = (user) => {
    history.push({
      pathname: "/Update",
      state: { userToUpdate: user },
    });
  };

  const openDeleteConfirmationModal = (user) => {
    setUserToDelete(user);
    setIsDeleteModalOpen(true);
  };

  const openActiveConfirmationModal = (user) => {
    Axios.post(`${config.baseUrl}/ActUser`, {
      user_id: user.USER_ID,
    })
      .then(() => {
        console.log("User Activation successfully:", userToDelete);
        getfunction();
      })
      .catch((error) => {
        console.error("Error Activation user:", error);
      });
  };

  const confirmDelete = () => {
    if (userToDelete) {
      setUsersData((prevUsersData) =>
        prevUsersData.filter((u) => u.USER_ID !== userToDelete.USER_ID)
      );
      Axios.post(`${config.baseUrl}/delUser`, {
        user_id: userToDelete.USER_ID,
      })
        .then(() => {
          console.log("User deleted successfully:", userToDelete);
          setUserToDelete(null);
          getfunction();
          setIsDeleteModalOpen(false);
        })
        .catch((error) => {
          console.error("Error deleting user:", error);
        });
    }
  };

  const data = {
    columns: [
      { label: "First Name", field: "firstName", sort: "asc", width: 150 },
      { label: "Last Name", field: "lastName", sort: "asc", width: 150 },
      { label: "Email", field: "email", sort: "asc", width: 200 },
      { label: "Phone Number", field: "mobile", sort: "asc", width: 150 },
      { label: "City", field: "city", sort: "asc", width: 150 },
      { label: "Gender", field: "gender", sort: "asc", width: 100 },
      { label: "Status", field: "status", sort: "asc", width: 100 },
      { label: "Actions", field: "actions", width: 150 },
    ],
    rows: usersData.map((rec) => ({
      firstName: rec.FIRST_NAME,

      lastName: rec.LAST_NAME,
      email: rec.EMAIL,
      mobile: rec.MOBILE,
      city: rec.CITY,
      gender: rec.GENDER,
      status: rec.STATUS === "1" ? "InActive" : "Active",
      actions: (
        <>
          {!(UserDetailsData.ROLE === "11" || UserDetailsData.ROLE === "21") ? (
            <>
              {rec.STATUS === "0" ? (
                <div>
                  <MDBBtn
                    className="me-1"
                    color="info"
                    onClick={() => handleUpdate(rec)}
                    style={{
                      padding: "8px",
                      margin: "10px",
                      borderRadius: "5px",
                      fontSize: "10px",
                    }}
                  >
                    <MDBIcon fas icon="edit" />
                  </MDBBtn>

                  <MDBBtn
                    color="danger"
                    onClick={() => openDeleteConfirmationModal(rec)}
                    style={{
                      marginLeft: "10px",
                      padding: "8px",
                      borderRadius: "5px",
                      fontSize: "10px",
                    }}
                  >
                    <MDBIcon fas icon="trash" />
                  </MDBBtn>
                </div>
              ) : (
                <MDBBtn
                  className="me-1"
                  color="info"
                  onClick={() => openActiveConfirmationModal(rec)}
                  style={{
                    padding: "8px",
                    margin: "10px",
                    borderRadius: "5px",
                    fontSize: "10px",
                  }}
                >
                  Activate
                </MDBBtn>
              )}
            </>
          ) : null}
        </>
      ),
      createdDate:
        rec.CREATED_DATE !== null
          ? format(new Date(rec.CREATED_DATE), "MMM d, yyyy").toString()
          : "",
    })),
  };
  const handleRowClick = (data) => {
    console.log(data, "data");
  };
  return (
    <>
      {/* <h2
        className="mb-4"
        style={{
          fontWeight: "bold",
          height: "5%", // Adjust height as needed
          padding: "1% 2%",
          textAlign: "center",
          borderRadius: "8px",
          color: "white",
          backgroundColor: "#FF725E",
          display: "inline-block", // Make the element inline-block
        }}
      >
        Users List
      </h2> */}
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
          <MDBSpinner big className="custom-spinner" role="status">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        </div>
      ) : (
        <>
          <h3 className="pageheading">Users List </h3>
          <MDBDataTable
            style={{
              backgroundColor: "#FF725E",
              border: "1px solid #00000036;", // Set a default light border color
              borderColor: "#ddd", // Set border color to light gray
            }}
            ref={tableRef}
            className="usertable"
            striped
            bordered
            hover
            small={true}
            data={{
              ...data,
              // Add a click handler to each row
              rows: data.rows.map((row, index) => ({
                ...row,
                onClick: () => handleRowClick(row), // Call handleRowClick function with row data
                cursor: "pointer", // Show pointer cursor on hover
              })),
            }}
            noBottomColumns
            tbodyStyle={{ textAlign: "left" }} // Align text within table body cells to the left
            tbodyTdStyle={{ textAlign: "left" }}
          />

          <MDBModal
            className="modalDelete"
            isOpen={isDeleteModalOpen}
            toggle={() => setIsDeleteModalOpen(false)}
            centered
          >
            <MDBModalBody>
              Are you sure you want to delete this record?
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={() => setIsDeleteModalOpen(false)}>
                No
              </MDBBtn>
              <MDBBtn color="success" onClick={confirmDelete}>
                Yes
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </>
      )}
    </>
  );
};

export default UserList;
