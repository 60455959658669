
import React, { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import config from "../../config";
import { MDBDataTable } from "mdbreact";
import { MDBSpinner } from "mdbreact";
import "./demo.css";

const Household = () => {
    const [formData, setFormData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(true);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${config.baseUrl}/HouseholdformData`);
            console.log("Full response:", response);
            console.log("Response data:", response.data);

            const data = Array.isArray(response.data.procedureData) ? response.data.procedureData : [];
            console.log("Procedure Data:", data);

            const formattedData = data.map((item) => ({
                ...item,
                FORM_DATA: item.FORM_DATA ? JSON.parse(item.FORM_DATA) : {},
                userId: uuidv4(),
            }));

            console.log('Formatted response data:', formattedData);
            setFormData(formattedData);
            setFilteredData(formattedData);
        } catch (error) {
            console.error("Error fetching follow-up data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        console.log("Form data of Household ups:", formData);
    }, [formData]);

    // Handle date range filter
    // const handleFilterByDateRange = async () => {
    //     console.log("Fetching data based on date range...");
    //     try {
    //         const response = await axios.get(`${config.baseUrl}/HouseholdformData`);
    //         if (Array.isArray(response.data.recordset)) {
    //             const formattedData = response.data.recordset
    //                 .map((item) => ({
    //                     ...item,
    //                     FORM_DATA: item.FORM_DATA ? JSON.parse(item.FORM_DATA) : null,
    //                     userId: uuidv4(),
    //                     interviewerId: uuidv4(),
    //                 }))
    //                 .filter((item) => item.FORM_DATA); // Filter out invalid data
    //             console.log("Formatted Data:", formattedData);

    //             // Add one day to endDate for inclusive filtering
    //             const inclusiveEndDate = new Date(endDate);
    //             inclusiveEndDate.setDate(inclusiveEndDate.getDate() + 1);

    //             // Filter records based on date range
    //             const filteredData = formattedData.filter((item) => {
    //                 const itemDate = new Date(item.CREATED_DATE);
    //                 console.log("Item Created Date:", itemDate);
    //                 return itemDate >= startDate && itemDate < inclusiveEndDate;
    //             });

    //             console.log("Filtered Data Between Dates:", filteredData);
    //             setFormData(filteredData); // Update state with filtered data
    //         } else {
    //             console.error("Invalid data format received:", response.data);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching or filtering data:", error);
    //     }
    // };

    const handleFilterByDateRange = async () => {
        const formatDateToYYYYMMDD = (dateString) => {
            const date = new Date(dateString); // Parse the string into a Date object
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        const formattedStartDate = startDate ? formatDateToYYYYMMDD(startDate) : null;
        const formattedEndDate = endDate ? formatDateToYYYYMMDD(endDate) : null;

        console.log('Formatted Start Date:', formattedStartDate);
        console.log('Formatted End Date:', formattedEndDate);
        setLoading(true);
        try {
            const response = await axios.get(`${config.baseUrl}/householddatadatefilter`, {
                params: { startDate: formattedStartDate, endDate: formattedEndDate },
            });

            console.log("Formatted Start Date (response):", formattedStartDate);
            console.log("Formatted End Date (response):", formattedEndDate);

            // Check if data is empty before rendering
            if (response.data.queryData.length === 0) { // Access correct property and check length
                // console.log("No data found for the selected date range.");
                alert("No data found for the selected date range.");
                // setDialogMessage("No data found for the selected date range.");
            } else {
                console.log("Filtered Data Response:", response.data.queryData);
                console.log("Filtered Data Response length:", response.data.queryData.length); // Log length

                setFilteredData(response.data.queryData);
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching or filtering data:", {
                message: error.message,
                response: error.response?.data,
                stack: error.stack,
            });
            alert("Failed to fetch filtered data. Please try again.");
            // setDialogMessage("Failed to fetch filtered data. Please try again.");
        }
    };

    // Enable apply button when both dates are selected
    useEffect(() => {
        if (startDate && endDate) {
            setIsApplyButtonDisabled(false);
        } else {
            setIsApplyButtonDisabled(true);
        }
    }, [startDate, endDate]);

    const generateHeaders = () => {
        if (formData.length > 0) {
            const firstItem = formData[0]; // Use the first item directly
            // List of keys you want to exclude from headers
            const excludedHeaders = ['FORM_DATA', 'userId'];

            return Object.keys(firstItem)
                .filter((key) => !excludedHeaders.includes(key)) // Exclude unwanted headers
                .map((key) => ({ label: key, field: key, sort: 'asc' }));
        }
        return [];
    };


    const handleDownloadCSV = () => {
        // Filter columns to exclude unwanted ones (e.g., FORM_DATA, userId)
        const excludedKeys = ['FORM_DATA', 'userId'];

        const filteredColumns = columns.filter(column => !excludedKeys.includes(column.field));

        const csvData = rows.map((row) => {
            return Object.keys(row)
                .filter((key) => !excludedKeys.includes(key))  // Exclude unwanted columns
                .map((key) => row[key]); // Extract the values for the row
        });

        // Generate CSV content
        const csvContent = "data:text/csv;charset=utf-8,"
            + [filteredColumns.map(column => column.label).join(",")] // Add headers
            + "\n"
            + csvData.map(row => row.join(",")).join("\n"); // Add rows

        // Create a download link and trigger click
        const link = document.createElement("a");
        link.href = encodeURI(csvContent);
        link.target = "_blank";
        link.download = "Household_data.csv";
        link.click();
    };

    const generateRowData = () => {
        const excludedKeys = ['FORM_DATA', 'userId']; // List of columns to exclude

        return filteredData.map((item) => {
            const rowData = {};

            // Loop through each key of the item and exclude unwanted ones
            Object.keys(item).forEach((key) => {
                if (!excludedKeys.includes(key)) {
                    // If the value is an object, stringify it; otherwise, just take the value or "N/A"
                    rowData[key] = typeof item[key] === 'object' ? JSON.stringify(item[key]) : item[key] || "N/A";
                }
            });

            return rowData;
        });
    };



    const columns = generateHeaders();
    const rows = generateRowData();

    return (
        <>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
                    <MDBSpinner big className="custom-spinner" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </MDBSpinner>
                </div>
            ) : (
                <>
                    <div>
                        <div style={{ display: "flex" }} className="testpagemain">
                            <h3 className="pageheading">Household Form Data</h3>
                            <div className="testpage">
                                <label htmlFor="startDate" style={{ margin: 0 }}>
                                    Start Date:{" "}
                                </label>
                                <input
                                    type="date"
                                    id="startDate"
                                    value={startDate ? startDate.toISOString().split("T")[0] : ""}
                                    onChange={(e) => setStartDate(new Date(e.target.value))}
                                    style={{ padding: "3px", borderRadius: "3px" }}

                                />
                                <label htmlFor="endDate" style={{ marginLeft: "20px", marginBottom: 0 }}>
                                    End Date:
                                </label>
                                <input
                                    type="date"
                                    id="endDate"
                                    value={endDate ? endDate.toISOString().split("T")[0] : ""}
                                    onChange={(e) => setEndDate(new Date(e.target.value))}
                                    style={{ padding: "3px", borderRadius: "3px" }}
                                />
                                <button
                                    style={{ marginLeft: "30px", borderRadius: "7px", padding: "6px", backgroundColor: "#0341fc", color: "white" }}
                                    onClick={handleFilterByDateRange}
                                    disabled={!startDate || !endDate}
                                >
                                    Apply Date Range
                                </button>

                            </div>
                        </div>

                        <MDBDataTable
                            className="table-container"
                            striped
                            bordered
                            small
                            caption="Household Form Data"
                            data={{ columns, rows }}
                            noBottomColumns={true}
                        />

                        {/* Keep the download button always visible */}
                        <button className="btn btn-primary" onClick={handleDownloadCSV}
                            style={{ backgroundColor: '#004085', borderColor: '#003366' }}
                        >
                            Download
                        </button>
                    </div>
                </>
            )}
        </>
    );
};

export default Household;

